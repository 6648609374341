import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { demos } from '../../data';

const Demos = () => {
  const title = 'Demonstration videos';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <table>
        <thead>
          <tr>
            <th>English</th>
            <th>中文</th>
          </tr>
        </thead>

        <tbody>
          {demos.map((demo, index) => (
            <tr key={index}>
              <td>
                <a href={demo.enLink}>{demo.enName}</a>
              </td>
              <td>
                <a href={demo.zhLink}>{demo.zhName}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default Demos;
